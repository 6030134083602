import isEmptyOrNil from '@vangst/lib/isEmptyOrNil'
import omitEmptyOrNil from '@vangst/lib/omitEmptyOrNil'
import toBoolean from '@vangst/lib/toBoolean'
import toNumber from '@vangst/lib/toNumber'
import useCreateJobPosting from '@vangst/services/oogst/jobPosting/useCreateJobPosting'
import useUpdateJobPosting from '@vangst/services/oogst/jobPosting/useUpdateJobPosting'
import {
  AtsRecordTypeEnum,
  JobPostingAtsStatusEnum,
  JobPostingFragment,
  JobPostingInput,
  JobPostingLicenseFragment,
  JobPosting_CredentialFragment,
} from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import Routes from '@vangst/services/routes'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { serializeSlateForForm } from '../../editor/serializers'

type PropsType = {
  readonly displayname?: string
  readonly jobPosting?: JobPostingFragment
  readonly jobPostingInput?: JobPostingInput & {
    readonly recruiters: any
    readonly accountExecs: any
    readonly timeSheetManagers: any
  }
  readonly setShowDateError?: (state: boolean) => void
  readonly setShowAssociatedClientError?: (state: boolean) => void
}
// hi
export default function useFormEditJob(props: PropsType) {
  const {
    displayname,
    jobPosting,
    jobPostingInput,
    setShowDateError,
    setShowAssociatedClientError,
  } = props
  const jobId = jobPosting?.id
  const { viewer } = useViewer()
  const { push, replace } = useRouter()

  const pusher = useCallback(
    (id: string) => push(Routes.JOBS_DETAIL.replace(':id', id)),
    [push],
  )

  const reset = useCallback(() => {
    if (jobPostingInput?.slug) {
      pusher(jobPostingInput.slug)
      return
    }
    replace(Routes.DASHBOARD)
  }, [jobPostingInput, pusher, replace])

  const formatAssociatedUsers = (
    property: 'recruiters' | 'accountExecs' | 'timeSheetManagers',
  ) => {
    if (!jobPostingInput?.[property]?.length) {
      return []
    }
    const results = jobPostingInput?.[property].map((user: any) => {
      return user.id
    })
    return results
  }

  const serializeLicensesForForm = (licenses: JobPostingLicenseFragment[]) => {
    return licenses?.map((license: JobPostingLicenseFragment) => ({
      id: license?.id,
      licenseId: license?.license?.id,
    }))
  }

  const serializeCredentialsForForm = (
    credentials: JobPosting_CredentialFragment[],
  ) => {
    return credentials?.map((credential: any) => ({
      id: credential?.id,
      credentialId: credential?.credential.id,
    }))
  }

  const methods = useForm<JobPostingInput>({
    defaultValues: {
      ...jobPostingInput,
      // @ts-ignore input shape differs from fragment shape
      associatedClient:
        jobPostingInput?.associatedClient?.displayname || displayname,
      description: serializeSlateForForm(jobPostingInput?.description),
      education: serializeSlateForForm(jobPostingInput?.education),
      experience: serializeSlateForForm(jobPostingInput?.experience),
      atsRecordType:
        jobPostingInput?.atsRecordType || AtsRecordTypeEnum.PlatformFree,
      responsibilities: serializeSlateForForm(
        jobPostingInput?.responsibilities,
      ),
      physicalRequirements: serializeSlateForForm(
        jobPostingInput?.physicalRequirements,
      ),
      workingEnvironment: serializeSlateForForm(
        jobPostingInput?.workingEnvironment,
      ),
      // @ts-ignore
      isPublished:
        jobPostingInput?.isPublished != null
          ? String(jobPostingInput?.isPublished)
          : viewer?.permissions?.internalUser
          ? 'false'
          : null,
      // @ts-ignore
      syndicateIndeed:
        jobPostingInput?.syndicateIndeed != null
          ? String(jobPostingInput?.syndicateIndeed)
          : 'false',
      gigsInfo: {
        billRate: jobPostingInput?.gigsInfo?.billRate,
        payRate: jobPostingInput?.gigsInfo?.payRate,
        salaryPayPeriod: jobPostingInput?.gigsInfo?.salaryPayPeriod,
        additionalRequirements: serializeSlateForForm(
          jobPostingInput?.gigsInfo?.additionalRequirements,
        ),
        parkingFacilityInformation: serializeSlateForForm(
          jobPostingInput?.gigsInfo?.parkingFacilityInformation,
        ),
        specialRequests: serializeSlateForForm(
          jobPostingInput?.gigsInfo?.specialRequests,
        ),
        dressCode: serializeSlateForForm(jobPostingInput?.gigsInfo?.dressCode),
        // @ts-ignore
        kickoffCall:
          jobPostingInput?.gigsInfo?.kickoffCall != null
            ? String(jobPostingInput?.gigsInfo?.kickoffCall)
            : jobPostingInput?.gigsInfo?.kickoffCall,
        // @ts-ignore
        engagementDeposit:
          jobPostingInput?.gigsInfo?.engagementDeposit != null
            ? String(jobPostingInput?.gigsInfo?.engagementDeposit)
            : 'false',
      },

      // @ts-ignore
      isRemote:
        jobPostingInput?.isRemote != null
          ? String(jobPostingInput?.isRemote)
          : 'false',
      recruiterIds: formatAssociatedUsers(`recruiters`),
      accountExecIds: formatAssociatedUsers(`accountExecs`),
      timeSheetManagerIds: formatAssociatedUsers(`timeSheetManagers`),
      // @ts-ignore
      primaryContactId: jobPostingInput?.primaryContact?.id,
      // @ts-ignore
      screeningId: jobPostingInput?.jobPostingScreening?.screening?.id,
      recommendedCredentials: serializeCredentialsForForm(
        jobPostingInput?.recommendedCredentials as JobPosting_CredentialFragment[],
      ),
      requiredLicenses: serializeLicensesForForm(
        jobPostingInput?.requiredLicenses as JobPostingLicenseFragment[],
      ),
    },
  })

  const create = useCreateJobPosting({
    onSuccess: (data) => pusher(data?.createJobPosting.slug),
  })

  const update = useUpdateJobPosting({
    onSuccess: (data) => {
      pusher(data?.updateJobPosting.slug)
    },
  })

  const error = create.error || update.error
  const saving = create.isLoading || update.isLoading

  const submit = useCallback(
    async (input: JobPostingInput) => {
      // serialize for the api
      const newPlatformFreeJob =
        !jobId && input?.atsRecordType == 'Platform_Free'
      const endIsBeforeStart =
        new Date(input?.startDate) > new Date(input?.endDate)
      if (
        !isEmptyOrNil(input?.endDate) &&
        endIsBeforeStart &&
        setShowDateError != null
      ) {
        setShowDateError(true)
        return
      }
      if (
        isEmptyOrNil(input?.associatedClient) &&
        input?.atsRecordType == 'Boutique_GIGS' &&
        setShowAssociatedClientError != null
      ) {
        setShowAssociatedClientError(true)
        return
      }
      const nextInput = {
        ...input,
        atsRecordType: input?.atsRecordType,
        atsStatus: input?.atsStatus || JobPostingAtsStatusEnum.Pending,
        isPublished: toBoolean(input?.isPublished),
        syndicateIndeed: toBoolean(input?.syndicateIndeed),
        isRemote: toBoolean(input?.isRemote),
        // @ts-ignore
        recruiterIds: input?.recruiterIds,
        accountExecIds: input?.accountExecIds,
        timeSheetManagerIds: input?.timeSheetManagerIds,
        gigsInfo: {
          costCenterCreated: toBoolean(input?.gigsInfo?.costCenterCreated),
          engagementDeposit: toBoolean(input?.gigsInfo?.engagementDeposit),
          kickoffCall: toBoolean(input?.gigsInfo?.kickoffCall),
          level: toNumber(input?.gigsInfo?.level),
          payRate: toNumber(input?.gigsInfo?.payRate),
          billRate: toNumber(input?.gigsInfo?.billRate),
          salaryPayPeriod: input?.gigsInfo?.salaryPayPeriod,
          additionalRequirements: input?.gigsInfo?.additionalRequirements,
          dressCode: input?.gigsInfo?.dressCode,
          specialRequests: input?.gigsInfo?.specialRequests,
          parkingFacilityInformation:
            input?.gigsInfo?.parkingFacilityInformation,
        },
        salaryRange: omitEmptyOrNil({
          maxValue: input?.salaryRange?.maxValue
            ? Number(input?.salaryRange?.maxValue)
            : null,
          minValue: input?.salaryRange?.minValue
            ? Number(input?.salaryRange?.minValue)
            : null,
          payPeriod: input?.salaryRange?.payPeriod,
        }),
        startDate: !isEmptyOrNil(input?.startDate)
          ? new Date(input?.startDate)?.toISOString()
          : null,
        endDate: !isEmptyOrNil(input?.endDate)
          ? new Date(input?.endDate)?.toISOString()
          : null,
        requiredLicenses: input?.requiredLicenses,
        recommendedCredentials:
          input?.recommendedCredentials &&
          input?.recommendedCredentials[0]?.credentialId
            ? input?.recommendedCredentials
            : [],
        quantity: toNumber(input?.quantity),
        depositAmount: toNumber(input?.depositAmount),
      }

      // remove fields that are not needed for the mutation:
      if (nextInput.isRemote && !nextInput?.location?.rawLocation) {
        delete nextInput.location
      }

      if (!viewer?.permissions?.internalUser) {
        delete nextInput.isPublished
        delete nextInput.recruiterIds
        delete nextInput.accountExecIds
        delete nextInput.timeSheetManagerIds
        // @ts-ignore
        delete nextInput.gigsInfo
      }

      if (nextInput?.screeningId == '') {
        delete nextInput?.screeningId
      }
      if (!nextInput?.employmentTypes) {
        delete nextInput?.employmentTypes
      }
      if (!nextInput?.salaryRange?.payPeriod) {
        delete nextInput?.salaryRange
      }
      if (!nextInput?.recommendedCredentials) {
        delete nextInput?.salaryRange
      }
      if (nextInput?.gigsInfo && !nextInput?.gigsInfo?.salaryPayPeriod) {
        delete nextInput?.gigsInfo.salaryPayPeriod
      }
      // @ts-ignore
      delete nextInput?.recruiters
      // @ts-ignore
      delete nextInput?.accountExecs
      // @ts-ignore
      delete nextInput?.timeSheetManagers
      // @ts-ignore
      delete nextInput.primaryContact
      // @ts-ignore
      delete nextInput.jobPostingScreening
      // @ts-ignore
      delete nextInput.externalId

      if (newPlatformFreeJob) {
        nextInput.client = { displayname }
        delete nextInput.associatedClient
      } else if (nextInput.associatedClient) {
        // Editing an existing gigs job, client will never change, but associated client can change
        // @ts-ignore
        nextInput.associatedClient = { displayname: nextInput.associatedClient }
      }

      jobId
        ? update.mutate({
            input: { ...nextInput, id: jobId },
          })
        : create.mutate({
            input: {
              ...nextInput,
            },
          })
    },
    [
      create,
      displayname,
      jobId,
      setShowDateError,
      setShowAssociatedClientError,
      update,
      viewer?.permissions?.internalUser,
    ],
  )

  return {
    error,
    methods,
    reset,
    saving,
    submit,
  }
}
