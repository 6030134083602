import {
  CompanyComputed,
  CompanyVars,
} from '@vangst/services/oogst/company/useCompany'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { useState } from 'react'
import { MdError } from 'react-icons/md'
import AlertText from '../../../components/feedback/AlertText'
import useScroll from '../../../components/hooks/useScroll'
import ClickyBtn from '../../../components/navigation/ClickyBtn'
import ClickyExplore from '../../../components/navigation/ClickyExplore'
import DialogAddUserToCompany from '../../job/feedback/DialogAddUserToCompany'
import DialogNewJobOrder from '../../job/feedback/DialogNewJobOrder'
import CompanyBenefitsRenderer from '../CompanyBenefitsRenderer'
import CompanyBlockSummary from '../CompanyBlockSummary'
import CompanyJobPostingsRenderer from '../CompanyJobPostingsRenderer'
import CompanyLocationsRenderer from '../CompanyLocationsRenderer'
import CompanyPhotosRenderer from '../CompanyPhotosRenderer'
import CompanyTrainingsRenderer from '../CompanyTrainingsRenderer'
import CompanyValuesRenderer from '../CompanyValuesRenderer'
import PostAJob from '../PostAJob'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly company?: CompanyComputed
  readonly variables: CompanyVars
}

function CompanyDetail(props: PropsType) {
  const { company, variables } = props
  const isPending = company?.name === null
  const { scrollToHash } = useScroll()
  const hasJobPostings = !!company?.countAvailablePositions
  const hasTrainings = !!company?.trainings?.pageInfo.totalCount
  const { viewer } = useViewer()
  const [showJobForm, setShowJobForm] = useState(false)
  const [showAddUserForm, setShowAddUserForm] = useState(false)

  return (
    <article className="contain-xy flow-y-sm">
      {viewer?.permissions.internalUser && (
        <div className="flow-x-xs ml-auto">
          <ClickyBtn
            className="mood-orange"
            onClick={() => setShowAddUserForm(true)}
          >
            Add User To Company
          </ClickyBtn>
        </div>
      )}
      {showJobForm && (
        <DialogNewJobOrder
          associatedClientDisplayname={variables.displayname}
          handleCancel={() => setShowJobForm(false)}
        />
      )}
      {showAddUserForm && (
        <DialogAddUserToCompany
          companyName={company?.name ?? ''}
          displayname={variables.displayname}
          handleCancel={() => setShowAddUserForm(false)}
        />
      )}
      <section>
        <CompanyBlockSummary at="content" variables={variables} />
      </section>

      {(hasJobPostings || hasTrainings) && (
        <section className="grid justify-center gap-2 sm:flex sm:justify-start">
          {hasJobPostings && (
            <ClickyExplore
              as="text-icon"
              className="motion-south w-full max-w-76"
              onClick={() => scrollToHash('#open-positions')}
            >
              View Available Positions
            </ClickyExplore>
          )}
          {hasTrainings && (
            <ClickyExplore
              as="text-icon"
              className="motion-south w-full max-w-76"
              onClick={() => scrollToHash('#training-courses')}
            >
              View Training Courses
            </ClickyExplore>
          )}
        </section>
      )}

      <div className="grid gap-4 sm:gap-8">
        <CompanyPhotosRenderer at="content" variables={variables} />

        <CompanyValuesRenderer at="disclosure" variables={variables} />

        <CompanyBenefitsRenderer at="disclosure" variables={variables} />

        <CompanyLocationsRenderer at="disclosure" variables={variables} />

        {(company?.permissions.createJobPosting || hasJobPostings) && (
          <section>
            <h2
              id="open-positions"
              className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600"
            >
              {isPending ? 'Unpublished Jobs' : 'Open Cannabis Jobs'}
            </h2>

            <div className="mood-grey-lightest rounded-b-md p-4">
              <div className="flex justify-center">
                <PostAJob slug={variables.displayname} />
              </div>
              {isPending && (
                <AlertText className="text-sm italic" icon={MdError}>
                  Jobs will be visible to the Vangst network once your profile
                  has been approved.
                </AlertText>
              )}

              <CompanyJobPostingsRenderer
                as="card"
                at="preview"
                take={6}
                className="mt-4"
                classNameList="grid-three-up min-h-80"
                variables={{
                  displayname: variables.displayname,
                  first: 24,
                }}
              />
              {company.countAvailablePositions > 0 && (
                <div className="mt-8 flex justify-center">
                  <ClickyExplore
                    as="text-icon"
                    href={company?.routes?.jobsIndex}
                  >
                    View All Jobs
                  </ClickyExplore>
                </div>
              )}
            </div>
          </section>
        )}

        {(company?.permissions.edit || hasTrainings) && (
          <section>
            <h2
              id="training-courses"
              className="mood-orange flex h-16 items-center rounded-t-md pl-6 font-600"
            >
              Training Courses
            </h2>
            <div className="mood-grey-lightest rounded-b-md p-4">
              <CompanyTrainingsRenderer
                as="card"
                at="preview"
                take={6}
                classNameList="grid-three-up min-h-80"
                variables={variables}
              />
            </div>
            <div className="mt-8 flex justify-center">
              <ClickyExplore
                as="text-icon"
                href={company?.routes?.trainingsIndex}
              >
                View All Trainings
              </ClickyExplore>
            </div>
          </section>
        )}
      </div>
    </article>
  )
}

export default CompanyDetail
