import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import Routes from '../../routes'
import { getSessionRedirect, setSessionRedirect } from '../../storage'
import {
  FetcherError,
  SignUpContextEnum,
  SignUpUserMutation,
  SignUpUserMutationVariables,
  useSignUpUserMutation,
} from '../types'
import { initializeViewer, invalidateViewer } from './useViewer'

type TOptions = UseMutationOptions<
  SignUpUserMutation,
  FetcherError,
  SignUpUserMutationVariables
>

function useSignUpUser(
  queryClient: QueryClient,
  options?: TOptions,
  skipRedirect?: boolean,
) {
  return useSignUpUserMutation({
    onSuccess: (data, { input }) => {
      invalidateViewer(queryClient)
      queryClient.clear()

      const { from } = input
      let redirectPath: string
      if (data.signUpUser.client?.displayname) {
        const displayname = data.signUpUser.client?.displayname
        redirectPath = Routes.COMPANIES_EDIT.replace(
          ':displayname',
          displayname,
        )
      } else {
        const username = data.signUpUser.username
        const profileRoute = Routes.MEMBERS_EDIT.replace(':username', username)
        const existing = getSessionRedirect()
        redirectPath =
          existing != null
            ? existing
            : from === SignUpContextEnum.SalaryGuide
            ? Routes.REPORTS_SALARY_GUIDE
            : from === SignUpContextEnum.LinkedInLandingPage
            ? profileRoute
            : from === SignUpContextEnum.MemberLandingPage
            ? profileRoute
            : profileRoute
      }

      if (redirectPath != null && !skipRedirect) {
        setSessionRedirect(redirectPath)
      }
      setSessionRedirect(redirectPath)
      initializeViewer(data.signUpUser, skipRedirect)
    },
    ...options,
  })
}

export default useSignUpUser
