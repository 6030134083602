import { getHeaders } from '@vangst/services/headers'
import { GraphQLClient } from 'graphql-request'

const ENDPOINT =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'production' &&
  process.env.NEXT_PUBLIC_OOGST_PRODUCTION
    ? process.env.NEXT_PUBLIC_OOGST_PRODUCTION
    : process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === 'demo' &&
      process.env.NEXT_PUBLIC_OOGST_DEMO
    ? process.env.NEXT_PUBLIC_OOGST_DEMO
    : process.env.NEXT_PUBLIC_OOGST_UAT != null
    ? // ? 'https://vangst-temporary-testing.herokuapp.com/graphql'
      process.env.NEXT_PUBLIC_OOGST_UAT
    : 'https://oogst.vangst.com/graphql'

const client = new GraphQLClient(ENDPOINT, {
  credentials: 'include',
  headers: getHeaders(),
})

export default client
